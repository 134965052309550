import { Container, ScrollView, Spinner, YStack, isWeb } from '@my/ui'
import React from 'react'
import { MainLayout, Meta } from 'app/components'
import { LandingHero } from './components/landing-hero'
import { LandingAppStoreLinks } from './components/landing-app-store-links'
import { LandingPageQueryOutput } from './queries/use-landing-page.query'
import { LandingFeatureCard } from './components/landing-feature-card'
import { CategoryTabs } from '../offer/containers'

export interface LandingScreenProps {
  hero?: LandingPageQueryOutput['HomePage']['hero']
  features?: LandingPageQueryOutput['HomePage']['features']
  meta?: LandingPageQueryOutput['HomePage']['meta']
  loading?: boolean
}

export function LandingScreen(props: LandingScreenProps) {
  const { hero, features, meta, loading } = props
  return (
    <MainLayout>
      <Meta title={meta?.title || "Student Saviour"} description={meta?.description} />
      {isWeb && <CategoryTabs />}
      <ScrollView>
        <Container size="base" gap="$6" gutter spaceTop spaceBottom>
          {hero && <LandingHero {...hero} />}
          {!loading && <LandingAppStoreLinks />}
          {features && features.length > 0 && (
            <YStack gap="$3.5">
              {features.map((feature, i) => (
                <LandingFeatureCard key={i} {...feature} reverse={i % 2 === 1} />
              ))}
            </YStack>
          )}
          {loading && (
            <YStack minHeight={100} jc="center" ai="center">
              <Spinner size="large" />
            </YStack>
          )}
        </Container>
      </ScrollView>
    </MainLayout>
  )
}
